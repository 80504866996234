import clsx from 'clsx'
import styles from './field-checkbox.module.css'
import stylesForm from '../../components/form/form.module.css'
import { onInvalidInputChange } from '../../utils'

export default function FieldCheckbox({ name, label, required }) {
    return (
        <label className={clsx(styles.field, 'js-field')}>
            <input
                className={styles.input}
                name={name}
                required={required}
                type='checkbox'
                onChange={onInvalidInputChange}
            />
            <div className={styles.box}><div></div></div>
            <div>
                <div className={styles.label}>{label}</div>
                <div className={clsx(stylesForm.field_error, 'js-error')} hidden></div>
            </div>
        </label>
    )
}