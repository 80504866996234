import clsx from 'clsx'
import { submitForm } from '../../utils'
import Button from '../button/button'
import FieldConfirmCode from '../field-confirm-code/field-confirm-code'
import stylesForm from '../form/form.module.css'
import { useRef, useState } from 'react'

export default function ConfirmCodeForm({ action, resend, description, onSuccess, sendOnInit }) {
    const formRef = useRef(null)
    const [filledFirstTime, setFilledFirstTime] = useState()

    function handleSubmit(event) {
        submitForm(event, 'POST', action, {
            loadingText: 'Проверяем...',
            unlockOnSuccess: false,
            onSuccess
        })
    }

    return (
        <form ref={formRef} onSubmit={handleSubmit} className={stylesForm.form}>
            <div className={stylesForm.description}>
                {description}
            </div>

            <input type="hidden" name={resend.name} value={resend.value ?? ''} />
            <FieldConfirmCode
                name='code'
                label='Код из письма'
                sendOnInit={sendOnInit}
                resend={resend}
                onFilled={() => {
                    if (!filledFirstTime) {
                        setFilledFirstTime(true)
                        formRef.current.querySelector('button[type=submit]').click()
                    }
                }}
            />

            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <div className={stylesForm.buttons_stack}>
                <Button primary>Продолжить</Button>
                <Button to='/auth'>Вернуться к авторизации</Button>
            </div>
        </form>
    )
}