import { Link, useNavigate } from 'react-router-dom'
import Button from '../button/button'
import FieldCheckbox from '../field-checkbox/field-checkbox'
import FieldText from '../field-text/field-text'
import stylesForm from '../form/form.module.css'
import { submitForm } from '../../utils'
import Turnstile, { useTurnstile } from 'react-turnstile'
import { useState } from 'react'
import { config } from '../../config'
import { useAuthStore } from '../../hooks/useAuthStore'
import clsx from 'clsx'

export default function SignupForm() {
    const [captchaPassed, setCaptchaPassed] = useState(false)
    const navigate = useNavigate()
    const turnstile = useTurnstile()
    const authStore = useAuthStore()

    function onSubmit(event) {
        submitForm(event, 'POST', '/auth/signup', {
            loadingText: 'Создаем аккаунт...',
            unlockOnSuccess: false,
            onSuccess: (response, formData) => {
                authStore.setSignupEmail(formData.email)
                navigate('/auth/confirm-signup')
            },
            onError() { setCaptchaPassed(false); turnstile.reset() }
        })
    }

    return (
        <form onSubmit={onSubmit} className={stylesForm.form}>
            <FieldText
                name='username'
                label='Логин или никнейм'
                placeholder='Введите логин или никнейм'
                hint='Латинские буквы и цифры'
                autoComplete='username'
                required
                inputProps={{
                    minLength: 3,
                    maxLength: 20
                }}
            />
            <FieldText
                name='email'
                label='Почта'
                placeholder='Введите почту'
                hint='На неё придет письмо для активации'
                type='email'
                autoComplete='email'
                required
                inputProps={{
                    minLength: 3,
                    maxLength: 256
                }}
            />
            <FieldText
                name='password'
                label='Пароль'
                placeholder='Введите пароль'
                hint='Минимум 6 символов'
                type='password'
                autoComplete='current-password'
                required
                inputProps={{
                    minLength: 6,
                    maxLength: 30
                }}
            />
            <FieldCheckbox
                name='agree'
                required
                label=<span>С пользовательским соглашением, включая <Link to='/agency' target='_blank' >агентский договор</Link>, <Link to='/rules' target='_blank' >правилами сайта</Link> ознакомился и принимаю в полном объеме</span>
            />
            <input type='hidden' name='inviteCode' value={localStorage.getItem(config.storageKeys.inviteCode) ?? ''} />
            <Turnstile
                sitekey={config.turnstileSiteKey}
                responseFieldName='captchaToken'
                size='flexible'
                theme='light'
                onVerify={() => { setCaptchaPassed(true) }}
            />
            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <Button primary disabled={!captchaPassed}>Создать аккаунт</Button>
        </form>
    )
}