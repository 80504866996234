export const config = {
    apiUrl: process.env.REACT_APP_API_URL,
    environment: process.env.REACT_APP_ENVIRONMENT,
    turnstileSiteKey: process.env.REACT_APP_TURNSTILE_SITE_KEY,
    socials: {
        tg: 'https://t.me/starvell',
        vk: 'https://vk.com/starvell',
        discord: 'https://discord.gg/CREbyMAdBn',
        youtube: 'https://www.youtube.com/@STARVELLCOM'
    },
    storageKeys: {
        inviteCode: 'starvell.invite-code',
    }
}