import Features from '../components/features/features';
import Footer from '../components/footer/footer';
import Jumbotron from '../components/jumbotron/jumbotron';
import stylesLayout from '../components/layout/layout.module.css';

export default function Landing() {
    return (
        <div className={stylesLayout.container_landing}>
            <Jumbotron />
            <Features />
            <Footer />
        </div>
    );
}