import { toast } from "react-toastify"
import success from '../../assets/toast/success.svg'
import error from '../../assets/toast/error.svg'
import warning from '../../assets/toast/warning.svg'
import close from '../../assets/toast/close.svg'

export const Msg = ({ title, text }) => {
    if (!Array.isArray(text) && typeof text !== 'string') text = text?.toString();
    text = Array.isArray(text) ? text.join(', ') : text

    return (
        <div className="toast__content">
            {title && <h4 className="toast__title">{title}</h4>}
            <p className="toast__text">{text}</p>
        </div>
    );
};

function CloseButton() {
    return <img src={close} className='toast__close' alt='' width={20} height={20} />
}

function showToast(type, icon, props, options = {}) {
    toast[type](<Msg {...props} />, {
        icon: <img src={icon} alt='' width={16} height={16} />,
        closeButton: <CloseButton />,
        ...options
    })
}

export const svtoast = {
    success(props, options) {
        showToast('success', success, { title: 'Успешно выполнено', ...props }, options)
    },
    error(props, options = {}) {
        showToast('error', error, { title: 'Ошибка', ...props }, options)
    },
    warning(props, options = {}) {
        showToast('warning', warning, props, options)
    }
}