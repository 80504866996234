import clsx from 'clsx'
import styles from './button.module.css'
import { useNavigate } from 'react-router-dom'

export default function Button({ children, primary, target = '_self', to, icon, iconleft, disabled, onClick, className, wide }) {
    const type = to ? 'button' : 'submit'
    const navigate = useNavigate()

    return (
        <button
            type={type}
            disabled={disabled}
            className={clsx(
                styles.button,
                {
                    [styles.wide]: wide,
                    [styles.primary]: primary,
                    [styles.icon_left]: iconleft
                },
                className
            )}
            onClick={(event) => {
                if (to) {
                    if (to.startsWith('/')) {
                        navigate(to)
                    } else {
                        window.open(to, target)
                    }
                } else {
                    onClick?.(event)
                }
            }}
        >
            {icon}
            {children}
        </button>
    )
}