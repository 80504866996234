import Layout from '../components/layout/layout';
import stylesLayout from '../components/layout/layout.module.css';
import styles from "./rules.module.css";
import clsx from 'clsx';

function Title ({children, marginBottom = 8}) {
    return <h1 style={{marginBottom: marginBottom}} className={styles.title}>{children}</h1>
}

function Subtitle ({children, marginBottom = 0}) {
    return <h2 style={{marginBottom: marginBottom}} className={styles.subtitle}>{children}</h2>
}

export default function RulesPage() {
    const rules = [
        {
            category: "1. Общие правила",
            values: [
                {
                    violation:
                        "1.1 Обмен контактными данными (Discord, Skype, Telegram, ВКонтакте, телефон и т. д.) с другим пользователем любым способом. Использование полученных от другого пользователя контактных данных. Поддержание связи в игре после выполнения заказа.",
                    punishment:
                        "Временная блокировка при первом и непреднамеренном нарушении. Блокировка аккаунта и отказ в выплатах при повторном нарушении.",
                },
                {
                    violation:
                        "1.2. Недобросовестное использование системы отзывов (накрутка, шантаж, безосновательное изменение отзыва по старому заказу и т. д.).",
                    punishment:
                        "Удаление отзыва. Блокировка аккаунта при повторном нарушении (без отказа в выплатах).",
                },
                {
                    violation:
                        "1.3. Предоставление информации третьим лицам или ее разглашение (имя пользователя, идентификатор игрока, сумма и т. д.) с целью причинения вреда пользователю.",
                    punishment:
                        "Блокировка всех аккаунтов. Отказ в выплатах. Блокировка всех новых аккаунтов нарушителя по мере их обнаружения.",
                },
                {
                    violation:
                        "1.4. Размещение на аватаре ссылок или названий любых интернет-ресурсов, противозаконной, порнографической или политической информации (флаги, исторические деятели и т. д.).",
                    punishment:
                        "Удаление аватара. Блокировка аккаунта при повторном нарушении (без отказа в выплатах).",
                },
                {
                    violation:
                        "1.5. Упоминание в имени ссылок или названий любых интернет-ресурсов, противозаконной или политической информации.",
                    punishment: "Смена никнейма.",
                },
                {
                    violation:
                        "1.6. Нарушение правил общения в личном чате или ином месте: оскорбления, угрозы, спам предложениями, флуд. Навязывание диалога с политическим подтекстом, отправка материалов эротического характера без согласия собеседника.",
                    punishment:
                        "Предупреждение. При многократных нарушениях возможна временная блокировка аккаунта.",
                },
                {
                    violation:
                        "1.7. Реклама, спам, массовая рассылка пользователям.",
                    punishment:
                        "Временная или постоянная блокировка аккаунта (без отказа в выплатах).",
                },
                {
                    violation:
                        "1.8. Мошенничество, обман других пользователей.",
                    punishment:
                        "Блокировка всех аккаунтов. Отказ в выплатах. Блокировка всех новых аккаунтов нарушителя по мере их обнаружения.",
                },
                {
                    violation:
                        "1.9. Обмен денежных средств из одной платёжной системы в другую, перевод денежных средств с одних реквизитов на другие без цели совершения каких-либо заказов. Кардинг, прочие финансовые махинации.",
                    punishment:
                        "Блокировка всех аккаунтов. Отказ в выплатах. Блокировка всех новых аккаунтов нарушителя по мере их обнаружения.",
                },
                {
                    violation:
                        "1.10. Передача ссылок на файлообменники и хостинги изображений без явной необходимости (например, при сделке с аккаунтом, передача логина и пароля через изображение, залитое на файлообменник).",
                    punishment:
                        "Предупреждение. Блокировка аккаунта при повторном нарушении (без отказа в выплатах).",
                },
                {
                    violation:
                        "1.11. Попытка покупки или продажи аккаунта STARVELL.",
                    punishment:
                        "Блокировка всех аккаунтов (без отказа в выплатах).",
                },
            ],
            bottomText: `При продаже в некоторых разделах сайта система предлагает участникам заказа перейти в Discord — достаточно просто нажать на ссылку. Однако обмен контактными данными в чате STARVELL или в самом Discord (например, добавление друг друга в друзья) по-прежнему считается нарушением.
Если вы планируете обменяться контактными данными с другим продавцом для совместного выполнения заказов, обратитесь в службу поддержки с соответствующим запросом.
Администрация оставляет за собой право на связь с продавцами под видом покупателей с целью выявления нарушений.
Администрация оставляет за собой право смягчить или отменить любое наложенное наказание, если оно нецелесообразно в каком-либо конкретном случае.`,
        },
        {
            category: "2. Правила для продавцов",
            values: [],
            categoryValues: [
                {category: '2.1. Запрещённые действия',
                    values: [
                {
                    violation:
                        "2.1.1. Попытка передать товар или предоставить услугу без оплаты через STARVELL, а также обмен товарами или услугами.",
                    punishment:
                        "Блокировка всех аккаунтов. Отказ в выплатах. Блокировка всех новых аккаунтов нарушителя по мере их обнаружения.",
                },
                {
                    violation:
                        "2.1.2. Просьба к покупателю о подтверждении заказа до его фактического выполнения ( при отсутствии признаков обмана ).",
                    punishment:
                        "Временная блокировка аккаунта. Отключение мгновенного вывода (при наличии).",
                },
                {
                    violation:
                        "2.1.3. Недобросовестная борьба с конкурентами (например, покупка товаров с целью написания негативных отзывов, подача ложных жалоб и т. д.).",
                    punishment: "Временная блокировка основной учетной записи.",
                },
                {
                    violation:
                        "2.1.4. Беспричинное игнорирование вопросов покупателей.",
                    punishment:
                        "Предупреждение. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.1.5. Осознанное размещение недействительных предложений либо предложений с недействительной ценой.",
                    punishment:
                        "Временная блокировка аккаунта. Возможна постоянная блокировка аккаунта (без отказа в выплатах).",
                },
                {
                    violation:
                        "2.1.6. При использовании функции «Автоматическая выдача» запрещается в поле «товары» вносить постороннюю информацию, в этом поле должны быть только товары.",
                    punishment:
                        "Предупреждение. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.1.7. Функцию «Автоматическая выдача» запрещается использовать для товаров, при продаже которых требуется общение или предоставление дополнительных услуг (например, перепривязки).",
                    punishment:
                        "Предупреждение. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.1.8. Нарушение правил, находящихся на странице размещения предложений (присутствуют в некоторых разделах). Игнорирование рассылок и уведомлений от администрации.",
                    punishment:
                        "Удаление предложений, предупреждение. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.1.9. Дублирование предложений, засорение таблицы предложений. Размещение предложений о покупке.",
                    punishment:
                        "Удаление предложений. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.1.10. Продажа товара или услуги через раздел, не предназначенный для этого (например, продажа игровой валюты через раздел предметов).",
                    punishment:
                        "Доначисление комиссии. Блокировка аккаунта при повторном нарушении (без отказа в выплатах).",
                }]},
                {category: '2.2. Запрещённые категории товаров',
                    values:
                [{
                    violation:
                        "2.2.1. Продажа товаров, полученных неправомерным путём (взломом, брутом, кардингом и т. д.).",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 60 дней.",
                },
                {
                    violation:
                        "2.2.2. Обучение неправомерной деятельности или продажа информации о ней (способы дюпа, кардинга и т. д.).",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 60 дней.",
                },
                {
                    violation: "2.2.3. Продажа персональных данных.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 60 дней.",
                },
                {
                    violation:
                        "2.2.4. Продажа и распространение нелицензионного или вредоносного ПО.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.5. Запрещена продажа аккаунтов социальных сетей и подписок на онлайн-кинотеатры, стриминговые сервисы, за исключением тех, для которых есть специальные разделы.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.6. Продажа телефонных номеров (в том числе виртуальных).",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.7. Продажа аккаунтов оптом, кроме тех, которые вы зарегистрировали лично.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.8. Продажа товаров и услуг эротического или порнографического содержания",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.9. Продажа товаров и услуг, связанных со спамом и массовой рассылкой сообщений.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.10. Продажа товаров и услуг, связанных со ставками и казино.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.11. Продажа любых способов доната и накрутки.",
                    punishment:
                        "Блокировка аккаунта и последующее прохождение процедуры идентификации для получения выплаты с него в течение 30 дней.",
                },
                {
                    violation:
                        "2.2.12. Перепродажа оффлайн активаций, геймпассов.",
                    punishment:
                        "Предупреждение. Временная блокировка аккаунта при повторных нарушениях.",
                },
                {
                    violation:
                        "2.2.13. Проведение лотерей и розыгрышей, продажа «рандома».",
                    punishment:
                        "Предупреждение. Блокировка аккаунта при повторном нарушении (без отказа в выплатах).",
                }]},
            ],
            bottomText:
                "Администрация оставляет за собой право проводить проверки размещаемых на площадке товаров.",
        },
        {
            category: "3. Ответственность продавцов",
            customViolationLabel: 'Степень ответственности',
            values: [
                {
                violation: '3.1.1. Отказ от консультирования покупателя касательно его заказа, игнорирование вопросов покупателя, отсутствие обслуживания.',
                punishment: 'Возврат до 100% от суммы заказа.'
            },
            {
                violation: '3.1.2. Отказ в выполнении задач, поставленных сотрудником арбитража, игнорирование его сообщений, а также безучастность во время разрешения спора.',
                punishment: 'Возврат до 100% от суммы заказа.'
            },
            {
                violation: '3.2.1. Администрация игры применила к покупателю санкции, поскольку игровая валюта или предметы были получены нелегальным путём (мошенничество, использование ошибок игры и т. д.).',
                punishment: 'Возврат до 100% от суммы заказа.'
            },
            {
                violation: '3.2.2. Администрация игры применила к покупателю санкции из-за покупки игровой валюты или предметов.',
                punishment: 'Возврат до 50% от суммы заказа.'
            },
            {
                violation: '3.3.1. Утрата покупателем аккаунта вследствие восстановления доступа к нему продавцом или первоначальным владельцем (через службу поддержки игры или каким-либо иным образом).',
                punishment: 'Возврат до 100% от суммы заказа.'
            },
            {
                violation: '3.3.2. Блокировка аккаунта администрацией игры из-за самого́ факта покупки/продажи аккаунта.',
                punishment: 'Возврат до 50% от суммы заказа.'
            },
            {
                violation: '3.4.1. Существенное изменение характеристик аккаунта, не оговорённое с покупателем (например изменение порядочности в Dota 2 при прокачке MMR, пропажа игровых предметов с аккаунта и т. д.).',
                punishment: 'Возможна компенсация нанесённого ущерба.'
            },
            {
                violation: '3.4.2. Выполнение услуги с отрицательным результатом (например, снижение рейтинга MMR в Dota 2 вместо его увеличения).',
                punishment: 'Возврат 100% от суммы заказа и компенсация нанесённого ущерба.'
            },
            {
                violation: '3.4.3. Блокировка аккаунта из-за некачественно оказываемой услуги (например, из-за использования продавцом бота или другого запрещённого издателем игры ПО).',
                punishment: 'Возврат 100% от суммы заказа, дополнительная компенсация.'
            },
            {
                violation: '3.4.4. Блокировка аккаунта из-за оказываемой услуги.',
                punishment: 'Возврат до 50% от суммы заказа.'
            },
            {
                violation: '3.4.5. Необоснованный отказ продавца от дальнейшего выполнения услуги.',
                punishment: 'Оплачивается 50% выполненной работы.'
            },
            {
                violation: '3.4.6. Задержка оговорённых с покупателем сроков.',
                punishment: 'Оплата выполненной работы снижается прямо пропорционально задержке. Например, если сроки увеличены в 1.5 раза, то оплата снижается в 1.5 раза.'
            },
        ],
            bottomText: <> Каждый спор между покупателем и продавцом индивидуален, и поэтому окончательное решение о степени ответственности продавца может <b>значительно</b> отличаться от описанного выше. При рассмотрении дела могут быть <b>учтены</b> договоренности между продавцом и покупателем, зафиксированные в чате.</>
        }
    ];
    return (
        <Layout>
            <div className={clsx(stylesLayout.content_wide, styles.rules_content)}>
                <Title marginBottom={16}>Правила</Title>
                <div className={styles.wrapper}>
                    {rules.map((el) => (
                        <div key={el.category} className={styles.block}>
                            <Subtitle marginBottom={8}>{el.category}</Subtitle>
                            {el.values.length > 0 && <div className={styles.block_split}>
                                <div
                                    className={clsx(
                                        styles.block_left,
                                        styles.block_half
                                    )}
                                >
                                    <span>Нарушение</span>
                                </div>
                                <div
                                    className={clsx(
                                        styles.block_right,
                                        styles.block_half
                                    )}
                                >
                                    <span>{el.customViolationLabel || 'Наказание'}</span>
                                </div>
                            </div>}
                            {el.values.map((valueEl, i) => (
                                <div
                                    key={i}
                                    className={clsx(styles.block_split,i !== el.values.length - 1 && styles.bordered )}
                                >
                                    <div
                                        className={
                                            styles.block_left +
                                            " " +
                                            styles.block_half
                                        }
                                    >
                                        <p className={valueEl.punishment === '' ? styles.category : ''}>{valueEl.violation}</p>
                                    </div>
                                    <div
                                        className={
                                            styles.block_right +
                                            " " +
                                            styles.block_half
                                        }
                                    >
                                        <p>{valueEl.punishment}</p>
                                    </div>
                                </div>
                            ))}
                               {el.categoryValues?.map((category) => (
                                <>
                                <h2 className={styles.category}>{category.category}</h2>
                                <div className={styles.block_split}>
                                <div
                                    className={clsx(
                                        styles.block_left,
                                        styles.block_half
                                    )}
                                >
                                    <span>Нарушение</span>
                                </div>
                                <div
                                    className={clsx(
                                        styles.block_right,
                                        styles.block_half
                                    )}
                                >
                                    <span>{el.customViolationLabel || 'Наказание'}</span>
                                </div>
                            </div>
                                {category.values.map((valueEl,i)=>
                                <div
                                    key={i}
                                    className={clsx(styles.block_split,i !== category.values.length - 1 && styles.bordered )}
                                >
                                    <div
                                        className={
                                            styles.block_left +
                                            " " +
                                            styles.block_half
                                        }
                                    >
                                        <p className={valueEl.punishment === '' ? styles.category : ''}>{valueEl.violation}</p>
                                    </div>
                                    <div
                                        className={
                                            styles.block_right +
                                            " " +
                                            styles.block_half
                                        }
                                    >
                                        <p>{valueEl.punishment}</p>
                                    </div>
                                </div>
                            )}
                           </> ))}
                            <div className={styles.bottom}>
                                <span>{el.bottomText}</span>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </Layout>
    );
}