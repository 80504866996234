import clsx from 'clsx';
import { useEffect, useState } from 'react';
import checkCircleIcon from '../assets/check-circle.svg';
import copyIcon from '../assets/copy.svg';
import faqArrowIcon from '../assets/faq-arrow.svg';
import discordCircleIcon from '../assets/socials/discord-circle.svg';
import tgCircleIcon from '../assets/socials/tg-circle.svg';
import tgWhiteIcon from '../assets/socials/tg-white.svg';
import vkCircleIcon from '../assets/socials/vk-circle.svg';
import vkWhiteIcon from '../assets/socials/vk-white.svg';
import ticketIcon from '../assets/ticket.svg';
import userIcon from '../assets/user-default.png';
import Button from '../components/button/button';
import Layout from '../components/layout/layout';
import { svtoast } from '../components/toast/toast';
import { config } from '../config';
import { useAuthStore } from '../hooks/useAuthStore';
import { api, pluralForms } from '../utils';
import { Accordion, AccordionItem as AccordionItemDefault } from '@szhsin/react-accordion';
import { Dialog } from '../components/dialog/dialog';
import UpdateLoginForm from '../components/auth/update-login-form';

import stylesButton from '../components/button/button.module.css';
import stylesGiveaway from './giveaway.module.css';
import stylesLayout from '../components/layout/layout.module.css';
import stylesDialog from '../components/dialog/dialog.module.css';

const TaskHeader = ({ task }) => (
    <div className={stylesGiveaway.task_header}>
        <header>
            <div className={stylesGiveaway.task_heading}>
                <div className={stylesGiveaway.task_number}>{task.number}</div>
                <h3 className={stylesGiveaway.task_title}>{task.title}</h3>
            </div>
            <div className={stylesGiveaway.task_ticket}>
                +1 БИЛЕТ
                <img src={ticketIcon} width={26} height={14} alt='Билет' />
            </div>
        </header>
        <div className={stylesGiveaway.task_description}>{task.description}</div>
    </div>
)

const TaskDone = () => (
    <div className={stylesGiveaway.task_done}>
        <img src={checkCircleIcon} width={16} height={16} alt='' />
        Выполнено
    </div>
)

const CopyField = () => {
    const authStore = useAuthStore()
    const inviteCode = authStore.user?.inviteCode ?? ''

    return (
        <div className={stylesGiveaway.copy_field} onClick={() => {
            navigator.clipboard.writeText('https://starvell.com/a/' + inviteCode)
            svtoast.success({ text: 'Ссылка успешно скопирована' })
        }}>
            <div className={stylesGiveaway.copy_field_text}>starvell.com/a/<span>{inviteCode.toLowerCase()}</span></div>
            <img src={copyIcon} width={16} height={16} alt='Скопировать' />
        </div>
    )
}

const AccordionItem = ({ header, ...rest }) => (
    <AccordionItemDefault
        {...rest}
        header={
            <>
                <h3 className={stylesGiveaway.faq_title}>{header}</h3>
                <img src={faqArrowIcon} width={16} height={16} alt='' className={stylesGiveaway.faq_arrow} />
            </>
        }
        className={stylesGiveaway.faq_section}
        buttonProps={{
            className: ({ isEnter }) => `${stylesGiveaway.faq_section_header} ${isEnter && stylesGiveaway.open}`,
        }}
        contentProps={{ className: stylesGiveaway.faq_content_wrapper }}
        panelProps={{ className: stylesGiveaway.faq_content }}
    />
);

const Faq = ({ items }) => {
    return (
        <Accordion transition transitionTimeout={250} className={stylesGiveaway.faq}>
            {items.map((item, i) => (
                <AccordionItem key={i} header={item.title} className={stylesGiveaway.faq_section}>
                    {item.text}
                </AccordionItem>
            ))}
        </Accordion>
    )
}

export const giveawayAmount = '100.000'

const socialsTasks = [
    { id: 'telegram', title: 'Telegram', icon: tgCircleIcon, url: config.socials.tg },
    { id: 'vk', title: 'ВКонтакте', icon: vkCircleIcon, url: config.socials.vk },
    { id: 'discord', title: 'Discord', icon: discordCircleIcon, url: config.socials.discord },
]

const awaitUsersColors = [ '#CAC0FF', '#E1E3E7', '#C0EAFF' ]

export default function GiveawayPage() {
    const [shareDialogOpen, setShareDialogOpen] = useState(false)
    const [updateLoginDialogOpen, setUpdateLoginDialogOpen] = useState(false)
    const [data, setData] = useState({ position: 0, users: 0, invitedUsers: 0, positionChange: 0 })
    const [socialLoading, setSocialLoading] = useState({ vk: false, discord: false, telegram: false });
    const authStore = useAuthStore()
    const subscribedSocials = authStore.user?.giveawayProfile.socials ?? [];
    const taskSocialsCompleted = subscribedSocials.length === 3;
    const taskReferralsCompleted = data.invitedUsers > 0;
    const anyTaskCompleted = taskReferralsCompleted || taskSocialsCompleted;
    const inviteCode = authStore.user?.inviteCode ?? ''

    const socialsShare = [
        { id: 'tg', name: 'Telegram', icon: <img src={tgWhiteIcon} width={14} height={12} alt='' />, url: `https://t.me/share/url?url=https%3A%2F%2Fstarvell.com%2Fa%2F${inviteCode}&text=%E2%A0%80%0A%D0%9D%D0%BE%D0%B2%D1%8B%D0%B9%20%D0%BC%D0%B0%D1%80%D0%BA%D0%B5%D1%82%D0%BF%D0%BB%D0%B5%D0%B9%D1%81%20%D1%86%D0%B8%D1%84%D1%80%D0%BE%D0%B2%D1%8B%D1%85%20%D1%82%D0%BE%D0%B2%D0%B0%D1%80%D0%BE%D0%B2%20%D0%B8%20%D1%83%D1%81%D0%BB%D1%83%D0%B3%2C%20%D1%80%D0%B5%D0%B3%D0%B8%D1%81%D1%82%D1%80%D0%B8%D1%80%D1%83%D0%B9%D1%81%D1%8F%20%D0%B8%20%D0%BF%D1%80%D0%B8%D0%BD%D0%B8%D0%BC%D0%B0%D0%B9%20%D1%83%D1%87%D0%B0%D1%81%D1%82%D0%B8%D0%B5%20%20%D0%B2%20%D1%80%D0%BE%D0%B7%D1%8B%D0%B3%D1%80%D1%8B%D1%88%D0%B5%20${giveawayAmount}%20%D1%80%D1%83%D0%B1%D0%BB%D0%B5%D0%B9` },
        { id: 'vk', name: 'ВКонтакте', icon: <img src={vkWhiteIcon} width={17} height={10} alt='' />, url: `https://vk.com/share.php?url=https://starvell.com/a/${inviteCode}` },
    ]

    async function completeTask(social) {
        if (socialLoading[social] || subscribedSocials.includes(social)) return;

        try {
            setSocialLoading({ ...socialLoading, [social]: true })
            await api('POST', '/giveaway/complete-social-task', { social });
            await refresh();
        } catch (error) {
            svtoast.error({ text: error.message });
            console.error(error);
        } finally {
            setSocialLoading({ ...socialLoading, [social]: false })
        }
    }

    async function refresh() {
        if (!authStore.isLoggedIn()) return;

        try {
            const result = await Promise.all([
                api('GET', `/giveaway/position`),
                api('GET', `/giveaway/users-count`),
                api('GET', `/giveaway/invited-users-count`),
                api('POST', `/giveaway/calculate-position-change`),
                authStore.udpateUserData()
            ])

            authStore.login(result[4])

            setData({
                position: result[0].position,
                users: result[1].users,
                invitedUsers: result[2].users,
                positionChange: result[3].positionChange
            })
        } catch (error) {
            console.error(error)
            svtoast.error({ text: error.message })
        }
    }

    useEffect(() => {
        refresh().then(() => {
            if (authStore.user && authStore.user.username.startsWith('user-')) {
                setUpdateLoginDialogOpen(true)
            }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout className={stylesLayout.layout_giveaway}>
            <div className={clsx(stylesLayout.content_wide, stylesGiveaway.content_bg)}>
            <div className={stylesGiveaway.giveaway_container}>
                <div className={stylesGiveaway.await_header}>
                    <h3>ваша позиция в очереди</h3>
                    <h2>#{data.position === 0 ? '' : data.position}</h2>
                </div>

                <div className={clsx(stylesGiveaway.tasks_container, stylesGiveaway.await_container)}>
                    <h2><span>{data.users}</span> {pluralForms(data.users, ['пользователь ожидает', 'пользователя ожидают', 'пользователей ожидают'])}</h2>

                    <div className={stylesGiveaway.await_description}>
                        Если вы пригласите еще 1 человека по своей ссылке, то подниметесь на {data.positionChange} {pluralForms(data.positionChange, ['позицию', 'позиции', 'позиций'])}
                    </div>

                    <div className={stylesGiveaway.await_copy_field_label}>Ваша реферальная ссылка</div>
                    <CopyField />

                    <div className={stylesGiveaway.await_share_buttons}>
                        {socialsShare.map(social => (
                            <a href={social.url} key={social.id} className={clsx(stylesGiveaway.share_button, stylesGiveaway[social.id])} target='_blank' rel="noreferrer">
                                {social.icon}
                                {social.name}
                            </a>
                        ))}
                    </div>

                    <footer>
                        <div className={stylesGiveaway.await_footer_count}>
                            Вы пригласили: <b>{data.invitedUsers} {pluralForms(data.invitedUsers, ['пользователь', 'пользователя', 'пользователей'])}</b>
                        </div>
                        <div className={stylesGiveaway.await_invited_users}>
                            {Array(Math.min(3, data.invitedUsers)).fill().map((_, i) => (
                                <img key={i} src={userIcon} style={{ background: awaitUsersColors[i] }} width={32} height={32} alt='' />
                            ))}
                        </div>
                    </footer>
                </div>

                <div className={stylesGiveaway.giveaway_header}>
                    <h2>Розыгрыш на <span>{giveawayAmount} рублей</span></h2>
                    <h3>100 победителей по 1.000 рублей</h3>
                </div>

                <div className={clsx(stylesGiveaway.tasks_container, stylesGiveaway.tasks_container__tasks)}>
                    <div className={stylesGiveaway.tasks}>
                        <div className={stylesGiveaway.task}>
                            <TaskHeader task={{ number: 1, title: 'Подписаться на соц. сети', description: 'Необходимо подписаться на наши соц.сети в Telegram, VK и вступить в Discord Сервер' }} />
                            <div className={stylesGiveaway.task_socials}>
                                {socialsTasks.map((social, i) => (
                                    <button
                                        key={i}
                                        className={clsx(stylesGiveaway.social_button, { [stylesGiveaway.social_done]: subscribedSocials.includes(social.id) })}
                                        // disabled={taskSocialsCompleted || !authStore.user}
                                        onClick={() => { window.open(social.url, '_blank'); completeTask(social.id) }}
                                    >
                                        <img src={social.icon} width={24} height={24} alt='' />
                                        {social.title}
                                    </button>
                                ))}
                            </div>
                            {taskSocialsCompleted && <TaskDone />}
                        </div>
                        <div className={stylesGiveaway.task}>
                            <TaskHeader task={{ number: 2, title: 'Пригласить друга', description: 'Пользователь должен зарегистрироваться на сайте по вашей реферальной ссылке' }} />
                            <div className={stylesGiveaway.task_share}>
                                <CopyField />
                                <Button primary className={clsx(stylesButton.bdrs4)} onClick={() => setShareDialogOpen(true)}>Поделиться</Button>
                            </div>
                            {taskReferralsCompleted && <TaskDone />}
                        </div>
                    </div>
                    <div className={stylesGiveaway.tickets}>
                        <div className={stylesGiveaway.ticket_stats}>
                            <div className={stylesGiveaway.ticket_stats_count}>
                                У вас {authStore.user?.giveawayProfile.ticketCount ?? 0} {pluralForms(authStore.user?.giveawayProfile.ticketCount, ['билет', 'билета', 'билетов'])}
                                <img src={ticketIcon} width={26} height={14} alt='Билет' />
                            </div>
                            <div><span>*1 билет - 1 участие</span></div>
                        </div>
                        <Button
                            primary
                            type='button'
                            disabled={anyTaskCompleted}
                            className={clsx(stylesGiveaway.join_button, stylesButton.bdrs6)}
                            onClick={() => { svtoast.success({ title: 'Принять участие', text: 'Выполните хотя бы 1 условие чтобы принять участие в розыгрыше!' }) }}
                        >
                            {anyTaskCompleted ? 'Участие принято' : 'Принять участие'}
                        </Button>
                    </div>
                    <footer>
                        Выполните хотя бы 1 условие чтобы принять участие в розыгрыше
                    </footer>
                </div>

                <Faq items={[
                    { title: 'Как принять участие в розыгрыше?', text: 'За выполнение условий даются билеты для участия. Всего можно получить 1000 билетов. Выполните хотя бы 1 условие, чтобы принять участие в розыгрыше.' },
                    {
                        title: 'Как выполнить условия?',
                        text:
                        <>
                            1. Перейдите на 3 соц. сети и подпишитесь.<br/>
                            2. Пригласите друга на сайт, он должен перейти по вашей ссылке и зарегистрироваться на сайте после чего задание будет выполнено и вам будет начислен 1 билет
                        </>
                    },
                    { title: 'Как увеличить шансы на победу?', text: 'Чем больше условий выполните, тем больше билетов получите. Если у вас 1 билет, а у друга 5 билетов – у него шансы на победу в 5 раз больше. Расскажите другу о розыгрыше, чтобы повысить ваши шансы на победу.' },
                    { title: 'Как получить приз?', text: 'Мы автоматически выберем 100 победителей и вручим призы на баланс сайта, деньги можно вывести или потратить внутри сайта' },
                    { title: 'Что я получу за высокую позицию в очереди?', text: 'От позиции в очереди зависит то какой айди вы получите после открытия сайта. Если у вас была третья позиция то ваш айди будет таким https://starvell.com/users/3' },
                    { title: 'Когда сайт будет открыт?', text: 'Точной даты нету, на данный момент мы активно занимается разработкой проекта, следите за новостями в наших соц-сетях' },
                ]} />
            </div>
            </div>

            <Dialog title='Поделитесь ссылкой' open={shareDialogOpen} onClose={() => setShareDialogOpen(false)}>
                <div className={clsx(stylesGiveaway.await_share_buttons, stylesGiveaway.await_share_buttons__column)}>
                    {socialsShare.map(social => (
                        <a href={social.url} key={social.id} className={clsx(stylesGiveaway.share_button, stylesGiveaway[social.id])} target='_blank' rel="noreferrer">
                            {social.icon}
                            {social.name}
                        </a>
                    ))}
                </div>
            </Dialog>

            <Dialog title='Завершение регистрации' className={stylesDialog.w500} open={updateLoginDialogOpen} closeable={false}>
                <div className={stylesDialog.description}>Установите свой никнейм для завершения регистрации</div>
                <UpdateLoginForm onSuccess={() => {
                    svtoast.success({ text: 'Никнейм успешно изменен' })
                    setUpdateLoginDialogOpen(false);
                    refresh()
                }} />
            </Dialog>
        </Layout>
    );
}