import { createBrowserRouter, RouterProvider, redirect, useLocation, useNavigate, useParams } from "react-router-dom"
import Landing from './routes/landing'
import ErrorPage from './routes/error-page'
import Auth from './routes/auth'
import TextPage from "./routes/text"
import GiveawayPage from "./routes/giveaway"
import { useAuthStore } from "./hooks/useAuthStore"
import { config } from "./config"
import RulesPage from "./routes/rules"
import { useEffect } from "react"
import useAuthLogin from "./hooks/useAuthLogin"
import { api } from "./utils"
import { svtoast } from "./components/toast/toast"

function getUser() {
    return JSON.parse(localStorage.getItem('starvell-auth') ?? '{}').state?.user
}

function loaderGuestsOnly() {
    return getUser() ? redirect('/') : null
}

function loaderLoggedInOnly() {
    return !getUser() ? redirect('/auth') : null
}

function Homepage() {
    const { user, isLoggedIn } = useAuthStore()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const { login } = useAuthLogin({ replace: true })

    async function oauth() {
        if (!params.service || isLoggedIn()) return;

        try {
            const tokens = await api('GET', `/auth/${params.service}-redirect`, Object.fromEntries(new URLSearchParams(window.location.search)))
            await login(tokens, false)

            navigate('/giveaway', { replace: true })
        } catch (error) {
            console.error(error)
            svtoast.error({ text: error.message })
            navigate('/auth')
        }
    }

    useEffect(() => {
        if (location.pathname === '/giveaway' && !user) {
            navigate('/', { replace: true })
        } else {
            oauth()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location, user])

    return isLoggedIn()
        ? <GiveawayPage />
        : <Landing />
}

let router = createBrowserRouter([
    {
        path: '/',
        element: <Homepage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/a/:code',
        element: <Homepage />,
        errorElement: <ErrorPage />,
        async loader({ params }) {
            if (params.code.length > 100 || !/^[a-z-]+$/.test(params.code))
                throw new Response('', { status: 404, statusText: 'Not Found' })

            localStorage.setItem(config.storageKeys.inviteCode, params.code)

            return redirect('/')
        },
    },
    {
        path: '/auth',
        element: <Auth subpage='login' showOAuth />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/signup',
        element: <Auth subpage='signup' showOAuth />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/recover',
        element: <Auth subpage='recover' />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/new-password',
        element: <Auth subpage='new-password' />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/confirm-recover',
        element: <Auth subpage='confirm-recover' />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/confirm-signup',
        element: <Auth subpage='confirm-signup' />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/confirm-signup-send',
        element: <Auth subpage='confirm-signup-send' />,
        errorElement: <ErrorPage />,
        loader: loaderGuestsOnly,
    },
    {
        path: '/auth/callback/:service',
        element: <Homepage />,
        errorElement: <ErrorPage />,
        async loader({ params }) {
            if (!['vk', 'google'].includes(params.service))
                throw new Response('', { status: 404, statusText: 'Not Found' })

            return null
        },
    },
    {
        path: '/agency',
        element: <TextPage title='Агентский договор' url='/docs/agency.txt' />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/agreement',
        element: <TextPage title='Пользовательское соглашение' url='/docs/agreement.txt' />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/rules',
        element: <RulesPage />,
        errorElement: <ErrorPage />,
    },
    {
        path: '/giveaway',
        element: <Homepage />,
        errorElement: <ErrorPage />,
        loader: loaderLoggedInOnly
    },
])

export default function App() {
    return <RouterProvider router={router} fallbackElement={<p>Loading...</p>} />;
}

if (import.meta.hot) {
    import.meta.hot.dispose(() => router.dispose());
}
