import clsx from 'clsx'
import { submitForm } from '../../utils'
import Button from '../button/button'
import FieldText from '../field-text/field-text'
import stylesForm from '../form/form.module.css'
import { config } from '../../config'

export default function UpdateLoginForm({ onSuccess }) {
    const inviteCode = localStorage.getItem(config.storageKeys.inviteCode)

    function onSubmit(event) {
        submitForm(event, 'POST', '/users/process-new-auth0', {
            loadingText: 'Сохраняем...',
            unlockOnSuccess: true,
            onSuccess,
        })
    }

    return (
        <form onSubmit={onSubmit} className={stylesForm.form}>
            <FieldText
                name='username'
                label='Логин или никнейм'
                placeholder='Введите логин или никнейм'
                hint='Латинские буквы и цифры'
                autoComplete=''
                required
                inputProps={{
                    minLength: 3,
                    maxLength: 20
                }}
            />
            {inviteCode && (
                <input type='hidden' name='inviteCode' value={inviteCode} />
            )}
            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <Button primary>Сохранить</Button>
        </form>
    )
}