import { useEffect, useState } from 'react';
import Layout from '../components/layout/layout';
import stylesLayout from '../components/layout/layout.module.css';
import stylesTextPage from './text-page.module.css';
import { fetchurl } from '../utils';

export default function TextPage({ title, url }) {
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState()

    useEffect(() => {
        fetchurl('GET', url)
            .then((result) => {
                setData(result)
            })
            .catch((error) => {
                console.error(error)
            })
            .finally(() => {
                setLoading(false)
            })
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Layout>
            <div className={stylesLayout.content_wide}>
                <div className={stylesTextPage.content}>
                    <h2>{title}</h2>
                    <div className={stylesTextPage.text} dangerouslySetInnerHTML={{ __html: loading ? 'Загрузка...' : data }}></div>
                </div>
            </div>
        </Layout>
    );
}