import { useEffect, useRef } from "react"
import closeIcon from '../../assets/toast/close.svg';
import styles from './dialog.module.css';
import clsx from "clsx";

export function Dialog({ title, open, onClose, className, children, closeable = true }) {
    const dialogRef = useRef(null)

    function closeDialog() {
        dialogRef.current.classList.add(styles.closing)
        dialogRef.current.addEventListener('animationend', () => {
            dialogRef.current.classList.remove(styles.closing)
            dialogRef.current.close()
        }, { once : true })
    }

    useEffect(() => {
        if (open === dialogRef.current.matches('[open]')) return;

        if (open) {
            dialogRef.current.showModal()
        } else {
            closeDialog()
        }
    }, [open])

    return (
        <dialog className={clsx(styles.dialog, className, { [styles.closeable]: closeable })} ref={dialogRef} onClick={(event) => { if (event.target.nodeName === 'DIALOG') onClose?.() }}>
            <div>
                <h3 className={styles.title}>{title}</h3>
                <div className={styles.content}>
                    {children}
                </div>
            </div>

            {closeable && (
                <button className={styles.close_button} onClick={() => onClose?.()}>
                    <img src={closeIcon} width={20} height={20} alt='' />
                </button>
            )}
        </dialog>
    )
}