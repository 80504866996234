import styles from './field-text.module.css'
import stylesForm from '../../components/form/form.module.css'
import eye from '../../assets/eye.svg'
import eyeOff from '../../assets/eye-off.svg'
import clsx from 'clsx'
import { useState } from 'react'
import { onInvalidInputChange } from '../../utils'

export default function FieldText({ id = window.crypto.randomUUID(), type = 'text', name, label, hint, placeholder, onKeyDown, onInput, autoFocus, required, autoComplete, inputProps }) {
    const [inputType, setInputType] = useState(type)

    return (
        <div className={clsx(styles.field, styles[`type_${type}`], 'js-field')}>
            {label && (<label htmlFor={id}>{label}</label>)}

            <div className={styles.input_wrapper}>
                <input
                    id={id}
                    className={styles.input}
                    name={name}
                    type={inputType}
                    placeholder={placeholder}
                    onKeyDown={onKeyDown}
                    onInput={(e) => {
                        onInvalidInputChange(e)
                        onInput?.(e)
                    }}
                    autoFocus={autoFocus}
                    autoComplete={autoComplete}
                    required={required}
                    {...inputProps}
                />
                {type === 'password' && (<img src={inputType === 'password' ? eye : eyeOff} width={20} height={20} alt='' onClick={() => setInputType(inputType === 'password' ? 'text' : 'password')} />)}
            </div>
            <div className={clsx(stylesForm.field_error, 'js-error')} hidden></div>

            {hint && (<div className={styles.hint}>{hint}</div>)}
        </div>
    )
}