import { Link } from 'react-router-dom';
import logo4 from '../assets/4.svg';
import logo from '../assets/logo.svg';
import Layout from '../components/layout/layout';
import stylesLayout from '../components/layout/layout.module.css';
import stylesErrorPage from './error-page.module.css';

export default function ErrorPage() {
    return (
        <Layout>
            <div className={stylesLayout.content_wide}>
                <div className={stylesErrorPage.error_container}>
                    <div className={stylesErrorPage.logo404wrapper}>
                        <Link to='/' className={stylesErrorPage.logo404}>
                            <img src={logo4} width={95} height={123} alt='' />
                            <img src={logo} width={123} height={123} alt='' />
                            <img src={logo4} width={95} height={123} alt='' />
                        </Link>
                    </div>

                    <p>
                        Кажется, что вы потерялись среди нашей бесконечной вселенной STARVELL.<br />
                        Страница не найдена. Давайте вернёмся на <Link to='/'>главную?</Link>
                    </p>
                </div>
            </div>
        </Layout>
    );
}