import Footer from '../../components/footer/footer';
import Header from '../../components/header/header';
import stylesLayout from '../../components/layout/layout.module.css';
import 'react-toastify/dist/ReactToastify.css';
import '../toast/toast.css';
import { ToastContainer } from 'react-toastify';
import clsx from 'clsx';

export default function Layout({ children, className }) {
    return (
        <div className={clsx(stylesLayout.container, className)}>
            <Header />
            <main>
                {children}
            </main>
            <Footer />
            <ToastContainer
                position='bottom-right'
                autoClose={7000}
                hideProgressBar
                closeOnClick
                theme='starvell'
            />
        </div>
    );
}