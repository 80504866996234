import { create } from 'zustand'
import { persist } from 'zustand/middleware'
import { api } from '../utils'

export const useAuthStore = create(persist(
    (set, get) => ({
        user: null,
        tokens: null,
        signupEmail: null,
        recoverData: null,
        login: (user) => { set(() => ({ user: user ?? null })) },
        logout: () => { set({ user: null, tokens: null }) },
        isLoggedIn: () => !!get().user,
        setTokens: (tokens) => { set(() => ({ tokens })) },
        setSignupEmail: (signupEmail) => { set(() => ({ signupEmail })) },
        setRecoverData: (recoverData) => { set(() => ({ recoverData })) },
        async udpateUserData() {
            try {
                return await api('GET', '/users/me')
            } catch (error) {
                console.log(error)

                if (error.status && error.status < 500) {
                    this.logout()
                }
            }
        }
    }),
    {
        name: 'starvell-auth',
    },
))