import clsx from 'clsx'
import styles from './features.module.css'

export default function Features() {
    const features = [
        { id: 1, title: 'Безопасность и надежность', description: 'Защита сделок, продавец не получит деньги до тех пор пока покупатель не подтвердит что он получил товар или услугу' },
        { id: 2, title: 'Широкий выбор товаров и услуг', description: 'Всё в одном месте, игровая валюта, игровые предметы, аккаунты, услуги, прочее найдёте у нас на сайте' },
        { id: 3, title: 'Выгодные цены', description: 'Продавцы самостоятельно устанавливают цены на свои товары, а покупатели решают, готовы ли они их купить по этой цене' },
    ]

    return (
        <footer className={styles.features}>
            {features.map(feature => (
                <div key={feature.id} className={clsx(styles.feature, styles[`feature_${feature.id}`])}>
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                </div>
            ))}
        </footer>
    )
}