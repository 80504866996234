import styles from './field-confirm-code.module.css'
import stylesFieldText from '../field-text/field-text.module.css'
import { useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import { api } from '../../utils'
import { svtoast } from '../toast/toast'

export default function FieldConfirmCode({ label, resend, onFilled, sendOnInit }) {
    const inputsRef = useRef(null)
    const hiddenInputRef = useRef(null)
    const [resendLoading, setResendLoading] = useState(false)
    const NUMBERS_COUNT = 6;

    useEffect(() => {
        if (sendOnInit) {
            api('POST', resend.url, { [resend.name]: resend.value })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    async function handleResend(event) {
        event.preventDefault()

        if (resendLoading) return

        try {
            setResendLoading(true)
            await api('POST', resend.url, { [resend.name]: resend.value })
            svtoast.success({ title: 'Код отправлен', text: 'Мы отправили вам новый код подтверждения' })
        } catch (error) {
            console.error(error)
            svtoast.error({ text: error.message })
        } finally {
            setResendLoading(false)
        }
    }

    function isInt(str) {
        return Number.isInteger(parseInt(str, 10))
    }

    function onKeyDown(event) {
        if (event.code === 'Backspace') {
            event.currentTarget.value = ''

            if (event.currentTarget.previousElementSibling) {
                event.currentTarget.previousElementSibling.focus()
            }
        }
    }

    function onInput(event) {
        // Handle Ctrl+V
        if (event.currentTarget.value.length >= NUMBERS_COUNT) {
            const value = event.currentTarget.value

            for (let i = 1; i <= NUMBERS_COUNT; i++) {
                if (!isInt(value[i - 1])) continue
                event.currentTarget.parentElement.querySelector(`#code_${i}`).value = value[i - 1]
            }
        } else {
            const char = event.currentTarget.value.at(-1)
            event.currentTarget.value = isInt(char) ? char : ''

            // Focus next input
            if (event.currentTarget.value && event.currentTarget.nextElementSibling) {
                event.currentTarget.nextElementSibling.focus()
            }
        }

        const value = [ ...inputsRef.current.querySelectorAll('input') ].map(input => input.value).join('')

        hiddenInputRef.current.value = value.padEnd(NUMBERS_COUNT, '0')

        if (value.length === NUMBERS_COUNT) onFilled?.()
    }

    return (
        <div className={stylesFieldText.field}>
            <label>{label}</label>

            <div className={styles.inputs} ref={inputsRef}>
                {Array(NUMBERS_COUNT).fill().map((_, i) => (
                    <input
                        key={i}
                        inputMode='numeric'
                        className={clsx(stylesFieldText.input, stylesFieldText.large)}
                        id={`code_${i + 1}`}
                        onInput={onInput}
                        onKeyDown={onKeyDown}
                        onClick={(e) => e.currentTarget.select()}
                        autoFocus={i === 0}
                        required
                        autoComplete='off'
                    />
                ))}
            </div>

            <input type="hidden" name="code" ref={hiddenInputRef} />

            <div className={stylesFieldText.hint_action}>
                <a href="/" onClick={handleResend}>{resendLoading ? 'Отправляем...' : 'Отправить код повторно'}</a>
            </div>
        </div>
    )
}