import { useNavigate } from 'react-router-dom'
import { submitForm } from '../../utils'
import Button from '../button/button'
import FieldText from '../field-text/field-text'
import stylesForm from '../form/form.module.css'
import { useEffect, useRef } from 'react'
import { useAuthStore } from '../../hooks/useAuthStore'
import useAuthLogin from '../../hooks/useAuthLogin'
import clsx from 'clsx'

export default function NewPasswordForm() {
    const passwordRef = useRef(null)
    const passwordRepeatRef = useRef(null)
    const navigate = useNavigate()
    const authStore = useAuthStore()
    const { login } = useAuthLogin()

    useEffect(() => {
        if (!authStore.recoverData) navigate('/auth')
    })

    function handleSubmit(event) {
        event.preventDefault()

        submitForm(event, 'POST', '/auth/password-recovery', {
            loadingText: 'Сохраняем...',
            unlockOnSuccess: false,
            onSuccess: (response) => {
                authStore.setRecoverData(null)
                login(response)
            }
        })
    }

    return (
        <form onSubmit={handleSubmit} className={stylesForm.form}>
            <input type='hidden' name='login' value={authStore.recoverData?.login ?? ''} />
            <input type='hidden' name='code' value={authStore.recoverData?.code ?? ''} />
            <FieldText
                id='password'
                name='password'
                label='Пароль'
                placeholder='Введите новый пароль'
                hint='Минимум 6 символов'
                type='password'
                autoFocus
                autoComplete='new-password'
                required
                inputProps={{
                    ref: passwordRef,
                    minLength: 6,
                    maxLength: 30
                }}
            />
            <FieldText
                id='password_repeat'
                name='password_repeat'
                label='Повторите пароль'
                placeholder='Введите новый пароль еще раз'
                type='password'
                autoComplete='new-password'
                required
                onInput={(event) => {
                    passwordRepeatRef.current.setCustomValidity(passwordRef.current.value !== passwordRepeatRef.current.value ? 'Пароли должны совпадать' : '')
                }}
                inputProps={{
                    ref: passwordRepeatRef,
                    minLength: 6,
                    maxLength: 30
                }}
            />
            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <Button primary>Сохранить</Button>
        </form>
    )
}