import { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Turnstile, { useTurnstile } from 'react-turnstile'
import { config } from '../../config'
import useAuthLogin from '../../hooks/useAuthLogin'
import { submitForm } from '../../utils'
import Button from '../button/button'
import FieldText from '../field-text/field-text'
import stylesFieldText from '../field-text/field-text.module.css'
import stylesForm from '../form/form.module.css'
import clsx from 'clsx'
import { useAuthStore } from '../../hooks/useAuthStore'

export default function LoginForm() {
    const [captchaPassed, setCaptchaPassed] = useState(false)
    const turnstile = useTurnstile()
    const { login } = useAuthLogin()
    const navigate = useNavigate()
    const authStore = useAuthStore()

    function onSubmit(event) {
        submitForm(event, 'POST', '/auth/signin', {
            loadingText: 'Заходим...',
            unlockOnSuccess: false,
            onSuccess: login,
            onError(error, formData) {
                if (error?.status === 445) {
                    authStore.setSignupEmail(formData.login)
                    navigate('/auth/confirm-signup-send')
                }

                setCaptchaPassed(false);
                turnstile.reset()
            }
        })
    }

    return (
        <form onSubmit={onSubmit} className={stylesForm.form}>
            <FieldText
                name='login'
                label='Логин или почта'
                placeholder='Введите логин или почту'
                autoComplete='username email'
                required
                inputProps={{
                    minLength: 3,
                    maxLength: 256
                }}
            />
            <FieldText
                name='password'
                label='Пароль'
                placeholder='Введите пароль'
                hint=<div className={stylesFieldText.hint_action}><Link to='/auth/recover' style={{ fontWeight: 500, textDecoration: 'none' }}>Забыли пароль?</Link></div>
                required
                type='password'
                autoComplete='current-password'
                inputProps={{
                    minLength: 6,
                    maxLength: 30
                }}
            />
            <Turnstile
                sitekey={config.turnstileSiteKey}
                responseFieldName='captchaToken'
                size='flexible'
                theme='light'
                onVerify={() => { setCaptchaPassed(true) }}
            />
            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <Button primary disabled={!captchaPassed}>Войти в аккаунт</Button>
        </form>
    )
}