import { useNavigate } from "react-router-dom"
import { svtoast } from "../components/toast/toast"
import { useAuthStore } from "./useAuthStore"
import { api } from "../utils"

export default function useAuthLogin({ replace = false } = {}) {
    const authStore = useAuthStore()
    const navigate = useNavigate()

    /**
     * @param {{ accessToken: string; refreshToken: string }} tokens
     **/
    async function login(tokens, redirect = true) {
        authStore.setTokens(tokens)

        try {
            authStore.login(await api('GET', '/users/me'))
            if (redirect) navigate('/giveaway', { replace })
        } catch (error) {
            console.error(error)
            svtoast.error({ text: 'Произошла ошибка при входе на сайт' })
            navigate('/auth')
        }
    }

    return { login }
}