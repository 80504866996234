import { svtoast } from "./components/toast/toast"
import { config } from "./config"

class HttpError {
	constructor(message, status) {
		this.message = message
		this.status = status
	}
}

export async function fetchurl(method, url, params, options = {}) {
    const token = JSON.parse(localStorage.getItem('starvell-auth') ?? '{}')?.state?.tokens?.accessToken
    const headers = {}
    const body = params && method !== 'GET' ? JSON.stringify(params) : undefined

    if (method !== 'GET') headers['Content-Type'] = 'application/json'
    if (token) headers['Authorization'] = `Bearer ${token}`

    if (method === 'GET' && params) {
        url += '?' + (new URLSearchParams(Object.fromEntries(Object.entries(params).filter(([key, value]) => value !== undefined)))).toString()
    }

    const response = await fetch(url, {
        method,
        headers,
        body,
        credentials: 'include',
        ...options,
    });

    if (response.ok) {
        const responseData = response.headers.get('content-type')?.startsWith('application/json')
            ? await response.json()
            : await response.text()

        return responseData.data ?? responseData
    } else if (response.status < 500) {
        let json = await response.json()
        json = json.data ?? json

        // TODO: Refactor
        if (Array.isArray(json.message)) {
            throw json.message
        } else {
            throw new HttpError(json.message, response.status)
        }
    } else {
        throw new HttpError('Ошибка на сервере. Попробуйте еще раз позже.', response.status)
    }
}

export async function api(method, url, params, options) {
    return fetchurl(method, config.apiUrl + url, params, options)
}

export async function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms))
}

export function getFormData(formElement) {
    const formData = {}

    new FormData(formElement).forEach((value, key) => { formData[key] = value })

    return formData
}

document.addEventListener('invalid', function(e) {
    const errorElement = e.target.closest('.js-field')?.querySelector('.js-error')

    if (errorElement) {
        e.preventDefault()
        errorElement.hidden = false
        errorElement.textContent = e.target.validationMessage
    }
}, true)

export function onInvalidInputChange(e) {
    if (e.target.matches(':invalid')) {
        const errorElement = e.target.closest('.js-field')?.querySelector('.js-error')
        if (errorElement) errorElement.hidden = true
    }
}

export async function submitForm(event, method, url, options) {
    event.preventDefault()

    const form = event.target
    const errorElement = form.querySelector('.js-form-error')
    const submitButton = form.querySelector('button[type=submit]')
    const submitButtonInitText = submitButton.textContent
    const formData = getFormData(form)

    if (errorElement) errorElement.hidden = true

    try {
        submitButton.disabled = true
        submitButton.textContent = options.loadingText ?? 'Отправляем...'
        const response = await api(method, url, formData)
        // const response = {}
        // await sleep(1000)

        if (options.unlockOnSuccess ?? true) {
            submitButton.disabled = false
            submitButton.textContent = submitButtonInitText
        }

        await options.onSuccess?.(response, formData)
    } catch (error) {
        console.error(error)
        await options.onError?.(error, formData)

        if (errorElement) {
            errorElement.hidden = false
            errorElement.textContent = error.message ?? error
        } else {
            svtoast.error({ text: error.message ?? error })
        }

        submitButton.disabled = false
        submitButton.textContent = submitButtonInitText
    }
}

export function listenForOutsideClick(listening, setListening, menuRef, setIsOpen) {
    return () => {
        if (listening || !menuRef.current) return;

        setListening(true);

        ['click', 'touchstart'].forEach((type) => {
            document.addEventListener(type, event => {
                if (!menuRef.current?.contains(event.target)) setIsOpen(false)
            })
        })
    }
}

export function pluralForms(int, options) {
    if (!options || options.length !== 3) throw Error();
    return (options && options[(int % 100 > 4 && int % 100 < 20) ? 2 : [2, 0, 1, 1, 1, 2][(int % 10 < 5) ? int % 10 : 5]]);
}