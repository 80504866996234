import { useNavigate } from 'react-router-dom'
import { submitForm } from '../../utils'
import Button from '../button/button'
import FieldText from '../field-text/field-text'
import stylesForm from '../form/form.module.css'
import Turnstile, { useTurnstile } from 'react-turnstile'
import { useState } from 'react'
import { config } from '../../config'
import { useAuthStore } from '../../hooks/useAuthStore'
import clsx from 'clsx'

export default function RecoverPasswordForm() {
    const [captchaPassed, setCaptchaPassed] = useState(false)
    const navigate = useNavigate()
    const authStore = useAuthStore()
    const turnstile = useTurnstile()

    function onSubmit(event) {
        submitForm(event, 'POST', '/auth/password-recovery/send-code', {
            loadingText: 'Отправляем письмо...',
            unlockOnSuccess: true,
            onSuccess: (response, formData) => {
                authStore.setRecoverData({ login: formData.login })
                navigate('/auth/confirm-recover')
            },
            onError() { setCaptchaPassed(false); turnstile.reset() }
        })
    }

    return (
        <form onSubmit={onSubmit} className={stylesForm.form}>
            <FieldText
                name='login'
                label='Логин или почта'
                placeholder='Введите логин или почту'
                autoFocus
                required
                autoComplete='username email'
                inputProps={{
                    minLength: 3,
                    maxLength: 256
                }}
            />
            <Turnstile
                sitekey={config.turnstileSiteKey}
                responseFieldName='captchaToken'
                size='flexible'
                theme='light'
                onVerify={() => { setCaptchaPassed(true) }}
            />
            <div className={clsx(stylesForm.field_form_error, 'js-form-error')} hidden></div>
            <div className={stylesForm.buttons_stack}>
                <Button primary disabled={!captchaPassed}>Восстановить пароль</Button>
                <Button to='/auth'>Вернуться к авторизации</Button>
            </div>
        </form>
    )
}