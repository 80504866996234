import styles from './logo.module.css'
import logo from '../../assets/logo.svg'
import logoText from '../../assets/logo-text.svg'
import { Link } from 'react-router-dom'

export default function Logo() {
    return (
        <Link to='/' className={styles.logo}>
            <img src={logoText} width={118} height={24} alt='Starvell' className={styles.logo_text} />
            <img src={logo} width={18} height={18} alt='Starvell' className={styles.logo_small} />
        </Link>
    )
}