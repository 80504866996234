import Button from '../components/button/button';
import stylesForm from '../components/form/form.module.css';
import stylesTabs from '../components/tabs/tabs.module.css';
import SignupForm from '../components/auth/signup-form';
import LoginForm from '../components/auth/login-form';
import clsx from 'clsx';
import RecoverPasswordForm from '../components/auth/recover-password-form';
import NewPasswordForm from '../components/auth/new-password-form';
import ConfirmCodeForm from '../components/auth/confirm-code-form';
import Layout from '../components/layout/layout';
import oauthGoogle from '../assets/socials/oauth-google.svg'
import oauthVK from '../assets/socials/oauth-vk.svg'
import { useNavigate } from 'react-router-dom';
import { config } from '../config';
import { useAuthStore } from '../hooks/useAuthStore';
import useAuthLogin from '../hooks/useAuthLogin';
import { useEffect } from 'react';

export default function Auth({ showOAuth, subpage }) {
    const authStore = useAuthStore()
    const navigate = useNavigate()
    const { login } = useAuthLogin()
    const isLoginTab = ['login', 'recover', 'new-password', 'confirm-recover'].includes(subpage)

    useEffect(() => {
        if (
            (subpage === 'confirm-signup' && !authStore.signupEmail) ||
            (subpage === 'confirm-recover' && !authStore.recoverData)
        ) {
            navigate('/')
        }
    })

    return (
        <Layout>
            <div className={stylesForm.form_panel}>
                <div className={stylesTabs.tabs}>
                    <div className={clsx(stylesTabs.tab, { [stylesTabs.active]: isLoginTab })} onClick={() => { if (!isLoginTab) navigate('/auth') }}>Авторизация</div>
                    <div className={clsx(stylesTabs.tab, { [stylesTabs.active]: !isLoginTab })} onClick={() => { if (isLoginTab) navigate('/auth/signup') }}>Регистрация</div>
                </div>

                <div className={stylesTabs.tab_content}>
                    {(
                        (subpage === 'login' && <LoginForm />) ||
                        (subpage === 'recover' && <RecoverPasswordForm />) ||
                        (subpage === 'new-password' && <NewPasswordForm />) ||
                        ((subpage === 'confirm-signup' || subpage === 'confirm-signup-send') &&
                            <ConfirmCodeForm
                                action='/auth/confirm-email'
                                sendOnInit={subpage === 'confirm-signup-send'}
                                resend={{
                                    url: '/auth/email-confirmation',
                                    name: 'login',
                                    value: authStore.signupEmail
                                }}
                                description={authStore.signupEmail.includes('@')
                                    ? <span>Чтобы завершить регистрацию, введите код из 6 цифр который пришёл на почту <b>{authStore.signupEmail}</b></span>
                                    : <span>Чтобы завершить регистрацию, введите код из 6 цифр который пришёл на почту пользователя <b>{authStore.signupEmail}</b></span>
                                }
                                onSuccess={async (response, formData) => {
                                    authStore.setSignupEmail(null)
                                    await login(response)
                                }}
                            />
                        ) ||
                        (subpage === 'confirm-recover' &&
                            <ConfirmCodeForm
                                action={'/auth/password-recovery/validate-code'}
                                resend={{
                                    url: '/auth/password-recovery/send-code',
                                    name: 'login',
                                    value: authStore.recoverData.login
                                }}
                                description=<span>Чтобы подтвердить что вы действительно являетесь владельцем аккаунта, укажите код из 6 цифр который пришёл вам на почту.</span>
                                onSuccess={(response, formData) => {
                                    authStore.setRecoverData({ ...authStore.recoverData, code: formData.code })
                                    navigate('/auth/new-password')
                                }}
                            />
                        ) ||
                        (subpage === 'signup' && <SignupForm />)
                    )}

                    {showOAuth && (
                        <div className={stylesForm.oauth}>
                            <div className={stylesForm.separator}><span>или через</span></div>
                            <div className={stylesForm.buttons_stack}>
                                <Button
                                    to={config.apiUrl + '/auth/signin/google'}
                                    iconleft
                                    type='button'
                                    icon={<img src={oauthGoogle} width={24} height={24} alt='Google' />}
                                >
                                    Войти через Google
                                </Button>
                                <Button
                                    to={config.apiUrl + '/auth/signin/vk'}
                                    iconleft
                                    type='button'
                                    icon={<img src={oauthVK} width={24} height={24} alt='VK' />}
                                >
                                    Войти через ВКонтакте
                                </Button>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}